(function($) {
    "use strict";

    $(function() {
        
        $.fn.changeToTimeSince = function() {
            var date = this.text();

            if (typeof date !== 'object') {
                date = new Date(date);
            }

            var seconds = Math.floor((new Date() - date) / 1000),
                intervalType,
                diffMinute,
                diffYear;

            var interval = Math.floor(seconds / 31536000);

            if (interval >= 1) {
                intervalType = 'vite';
                diffYear = true;
            } else {
                interval = Math.floor(seconds / 2592000);
                if (interval >= 1) {
                    intervalType = 'muaj';
                } else {
                    interval = Math.floor(seconds / 86400);
                    if (interval >= 1) {
                        intervalType = 'ditë';
                    } else {
                        interval = Math.floor(seconds / 3600);
                        if (interval >= 1) { 
                            intervalType = "orë";
                        } else {
                            interval = Math.floor(seconds / 60);
                            if (interval >= 1) {
                                intervalType = "minuta";
                                diffMinute = true;
                            } else {
                                interval = seconds;
                                intervalType = "sekonda";
                            }
                        }
                    }
                }
            }

            if (diffMinute === true && interval === 1) {
                intervalType = 'minutë';
            }

            if(diffYear === true && interval === 1) {
                intervalType = 'vitë';
            }

            this.text(interval + ' ' + intervalType + ' më parë').addClass('date-human'); 
        };
    });
}(window.jQuery));