(function($, window, document) {
 /*   "use strict";*/

    $(function() {
        //##Variables
        var $menuToggle = $('.menu_area'),
         $navToggle = $('.main_nav'),
         $cancelForm = $('.cancel_button'),
         $searchToggle = $('.searchToggle'),
         $aboutUsForm = $('.form_about_us'),
         $contactButton = $('.contact_button'),
         $contactDefault = $('.contact_default'),
         $tabNavItem = $('.tab_titles li'), 
         $headerForm = $('.form-holder'); 


          $tabNavItem.click(function() { 
           if(!$(this).hasClass('active')) {  
               var this_id = $(this).attr("data-tab");   

               $(this).siblings().removeClass('active');  
               $(this).addClass('active');                   
 
               $(" .tabs_content .video_holder ").removeClass('active');
               $("#"+this_id).addClass('active');  

           };   
        }); 
    

        $menuToggle.click(function() {
            $('body').toggleClass('nav_toggled'); 
            if ($navToggle.is(':visible')) {
        
                $navToggle.velocity('stop').velocity("fadeOut", {
                    duration: 10,
                    easing: 'easeInOutQuad'
                });
            } else {
        
                $navToggle.velocity('stop').velocity("fadeIn", {
                    duration: 500,
                    easing: 'easeInOutQuad'
                });
            }
            
            if($('body').hasClass('search_toggled')){
                $('body').removeClass('search_toggled');    
                
                $headerForm.velocity("fadeOut", {
                    duration: 300,
                    easing: 'easeInOutQuad'
                });
            }
        });
        $searchToggle.click(function() {
            $('body').toggleClass('search_toggled');
            if ($headerForm.is(':visible')) {
                $headerForm.velocity('stop').velocity("fadeOut", {
                    duration: 10,
                    easing: 'easeInOutQuad'
                });
            } else {
                $headerForm.velocity('stop').velocity("fadeIn", {
                    duration: 300,
                    easing: 'easeInOutQuad'
                });
            }
        });


        // Init Wow Animations
        // new WOW().init();
 
        $contactButton.click(function() {
            $aboutUsForm.toggleClass('active');
            $contactDefault.toggleClass('in-active');
        });
        $cancelForm.click(function() {
            $aboutUsForm.toggleClass('active');
            $contactDefault.toggleClass('in-active');
        }); 

        $('.flexslider').flexslider({
            animation: "slide",
            animationLoop: false  
        });
        $('.sport-slider').flexslider({
            animation: "slide",
            animationLoop: false
        });
        $('.others_slider').flexslider({
            animation: "slide",
            animationLoop: false
        });

        $('.filter-label').click(function() {
            $('.filter-label').parent().removeClass('active');
            $(this).parent().addClass('active');
        });

        var filterItems = jQuery('.all_tag');
        var getPDK = jQuery('.pdk_tag');
        var getLDK = jQuery('.ldk_tag');
        var getVV = jQuery('.vv_tag');

        function resetSliderHTML ( ) {
            $('.zgjedhjet_slider').remove();
            $('.zgjContainer').append('<div class="zgjedhjet_slider"><ul class="slides loadingClass"></ul></div>');
        }
        function reinstantiateSlider ( ) {
            $('.zgjedhjet_slider').flexslider({
                animation: "slide",
                itemWidth: 215,
                slideshow: false,
                controlNav: false,
                directionNav: true,
                directionNav:  true,
                animationLoop: false,
                maxItems: 4,
                itemMargin: 15
            });
            jQuery('.zgjedhjet_slider .slides').removeClass('loadingClass');
        }
        function setNewSliderElements(elements) {
            resetSliderHTML();
            $(".zgjedhjet_slider .slides").append(elements);
            elements.find('.date-time').each(function() {
                if($(this).text() && $(this).hasClass('date-time') && !$(this).hasClass('date-human')){
                    $(this).changeToTimeSince();
                }
            });
            reinstantiateSlider();
        }
        setNewSliderElements(getPDK);
        
        jQuery('body').on('click','#all',function(){
            setNewSliderElements(filterItems);
        });
        jQuery('body').on('click','#pdk_tag',function(){
            setNewSliderElements(getPDK);
        });
        jQuery('body').on('click','#ldk_tag',function(){
            setNewSliderElements(getLDK);
        });
        jQuery('body').on('click','#vv_tag',function(){
            setNewSliderElements(getVV);
        });

        $('#back-to-top').on('click', function (e) {
            e.preventDefault();
            $('html,body').animate({
                scrollTop: 0
            }, 1000);
        });

        // $("#left_banner_rek").stick_in_parent({offset_top: 120}); 
        $("#left_element").stick_in_parent({offset_top: 380});
        $(".text_shadow").stick_in_parent({offset_top: 10}); 
        $(".rek_devider").stick_in_parent({offset_top: 550}); 
        // $(".right-rek").stick_in_parent({offset_top: 150}); 
        $("#right_side_rek").stick_in_parent({offset_top: 580});   
        $(window).scroll(function(){
          var threshold = 150; // number of pixels before bottom of page that you want to start fading
          var op = (($(document).height() - $(window).height()) - $(window).scrollTop()) / threshold;
            if( op <= 0.65 ){
                $(".stickydiv").hide();
            } else {
                $(".stickydiv").show();
            }
            $(".stickydiv").css("opacity", op ); 
        });

        var sections = $('section'),
            nav = $('nav'),
            nav_height = nav.outerHeight();

		$(window).on('scroll', function () {
	       var cur_pos = $(this).scrollTop();
		  
	       sections.each(function() {
    		    var top = $(this).offset().top - nav_height,
    		        bottom = top + $(this).outerHeight();
    		    
    		    if (cur_pos >= top && cur_pos <= bottom) {
    		      nav.find('a').removeClass('active');
    		      sections.removeClass('active');
    		      
    		      $(this).addClass('active');
    		      nav.find('a[href="#'+$(this).attr('id')+'"]').addClass('active');
    		    }
		      });
		});

		nav.find('a').on('click', function () {
            var $el = $(this), id = $el.attr('href');
		  
            $('html, body').animate({
	           scrollTop: $(id).offset().top - nav_height
            }, 500);
		  
	       return false;
		});

        function onScroll(){
            var scrollPos = $(document).scrollTop();
            
            $('#sidebar a').each(function () {
                var currLink = $(this);
                var refElement = $(currLink.attr("href"));
                if (refElement.position().top <= scrollPos && refElement.position().top + refElement.height() > scrollPos) {
                    $('#sidebar ul li a').removeClass("active"); 
                    currLink.addClass("active");
                }
                else{
                    currLink.removeClass("active");
                }
            }); 
        }


    	$(document).ready(function () {
            if ($('#checkdiv')[0]) {
                $(window).scroll(function(){
                    var window_top = $(window).scrollTop() + 20; 
                    // the "12" should equal the margin-top value for nav.stickydiv
                    var div_top = $('#checkdiv').offset().top;
                    if (window_top >= div_top) {
                        $('nav').addClass('stickydiv');
                    } else {
                        $('nav').removeClass('stickydiv');
                    }
                });  
            }


            $(document).on("scroll", onScroll);

            $('.stickydiv a[href^="#"]').on('click', function (e) {
            	e.preventDefault();
                $(document).off("scroll");
                
                $('a').each(function () {
                    $(this).removeClass('active');
                });

                $(this).addClass('active');
              
                var target = this.hash;

                $('html, body').stop().animate({
                    'scrollTop': $(target).offset().top + 2
                }, 600, 'swing', function () {
                    window.location.hash = target;
                    $(document).on("scroll", onScroll);
                });
            });

            $("#opinione").click(function (){
                $('html, body').animate({
                    scrollTop: $("#opinion").offset().top-290
                }, 700);
            });

            $('.date-time').each(function() {
                if($(this).text() && $(this).hasClass('date-time') && !$(this).hasClass('date-human')){
                    $(this).changeToTimeSince();
                }
            });
        });
        var loading = false;
        var post = 0;
        jQuery(window).scroll(function() {
           if(jQuery(window).scrollTop() + jQuery(window).height() > jQuery(document).height() - 100 && post < 3 && loading == false) {
                post++;
                var url = jQuery('.related_post_'+post).val();
                if (typeof url !== "undefined") {
                    loading = true;
                    jQuery('.other_post_ajax').append('<p class="loading-info" style="float: left;width: 100%;font-weight: 900;color: #212121;text-align: center;padding-top: 100px;">Duke u ngarkuar lajmi tjetër ...</p>')
                    jQuery.ajax({
                      url: url+"?ajax="+post,
                      success: function(html){
                        jQuery('.loading-info').remove();
                        jQuery(".other_post_ajax").append(html);
                        $('.date-time').each(function() {
                            if($(this).text() && $(this).hasClass('date-time') && !$(this).hasClass('date-human')){
                                $(this).changeToTimeSince();
                            }
                        });                        
                        loading = false;
                      }
                    });
                }
           }
        });
        var didScroll;
        var lastScrollTop = 0;
        var delta = 5;
        var navbarHeight = $('#header').outerHeight();

        $(window).scroll(function(){
            didScroll = true;
        });

        setInterval(function() {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 150);

        function hasScrolled() {
            var st = $(this).scrollTop();
            if (Math.abs(lastScrollTop - st) <= delta) {
                return;
            }

            if (st > lastScrollTop && st > navbarHeight){
                // Scroll Down
                $('.top_menu').removeClass('header-default').addClass('header-up');
            } else {
                // Scroll Up
                if(st + $(window).height() < $(document).height()) {
                    $('.top_menu' ).removeClass('header-up').addClass('header-down');
                }
            }
            
            lastScrollTop = st;
        }
        jQuery('.sport-slider').removeClass('loadingClass'); 
    }); 
 
}(window.jQuery, window, document));

